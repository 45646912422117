/* BEGIN DEPRECATED - old page sizing/mobile variables */
/* END DEPRECATED - old page sizing/mobile variables */
.error-boundary {
  text-align: center;
  font-weight: 700;
}
.error-boundary .error-id {
  font-weight: 400;
  font-style: italic;
}
