/* BEGIN DEPRECATED - old page sizing/mobile variables */
/* END DEPRECATED - old page sizing/mobile variables */
.loading-indicator-expand,
.loading-indicator-overlay {
  align-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-indicator-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
}

.loading-indicator {
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;
  width: 8.5714285714em;
  height: 8.5714285714em;
  background-color: #dddddd;
  border-radius: 50%;
  margin: 2.1428571429em auto;
  user-select: none;
  -webkit-transform: rotate(0);
}
.loading-indicator.size-100 {
  width: 7.1428571429em;
  height: 7.1428571429em;
}
.loading-indicator.size-30 {
  width: 2.1428571429em;
  height: 2.1428571429em;
}
.loading-indicator .loading-mask {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.loading-indicator .loading-mask .fill {
  fill: #ffffff;
}
.loading-indicator .loading-animation {
  position: absolute;
  top: 0.1428571429em;
  left: 0.1428571429em;
  right: 0.1428571429em;
  bottom: 0.1428571429em;
  border-radius: 50%;
  overflow: hidden;
}
.loading-indicator .loading-animation:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 33%;
  background-color: var(--theme-icon-light);
  animation: loadingSmall 1250ms ease-out forwards infinite;
}
.loading-indicator .loading-animation:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: var(--theme-icon-dark);
  animation: loadingLarge 1800ms ease-out forwards infinite;
}

@keyframes loadingLarge {
  0% {
    transform: translate(0%, 100%);
  }
  70% {
    transform: translate(0%, -100%);
  }
  100% {
    transform: translate(0%, -100%);
  }
}
@keyframes loadingSmall {
  from {
    transform: translate(0%, 303%);
  }
  to {
    transform: translate(0%, -303%);
  }
}
